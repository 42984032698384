import api from './api';
import {
  Owner,
  Site,
  SiteAssessment,
  SiteListMemberRole,
  SiteRoot,
  Vendor,
  SiteAssessmentImages,
  TiePoints,
} from '../../@types'

import {
  OwnerRequestResponse,
  SiteAssessmentsRequestResponse,
  SiteListMemberRoleRequestResponse,
  SitePeopleRequestResponse,
  SiteRequestResponse,
  SiteRootRequestResponse,
  UserInfoRequestResponse,
  VendorRequestResponse,
  SiteAssessmentImagesResponse,
} from './types';


const parseSiteRequestResponseToSite = (
  site: SiteRequestResponse | undefined
): Site | undefined => {
  if (site) {
    return {
      id: site.Id,
      ownerId: site.OwnerId,
      ownerSiteId: site.OwnerSiteId,
      structureTypeId: site.StructureTypeId,
      assetClassId: site.AssetClassId,
      ownershipTypeId: site.OwnershipTypeId,
      statusId: site.StatusId,
      shortName: site.ShortName,
      addressLine1: site.AddressLine1,
      addressCity: site.AddressCity,
      addressState: site.AddressState,
      postalCode: site.PostalCode,
      countyName: site.CountyName,
      latitude: site.Latitude,
      longitude: site.Longitude,
      accessCode: site.AccessCode,
      accessInstructions: site.AccessInstructions,
      user: site.User,
      structureHeightAgl: site.StructureHeightAgl,
      assetClassName: site.AssetClassName,
      structureTypeName: site.StructureTypeName,
      floodZoneDesignation: site.FloodZoneDesignation,
      elevation: site.Elevation,
      elevationResolution: site.ElevationResolution,
      owner: parseOwnerRequestResponseToOwner(site.Owner),
    };
  }

  return undefined;
};

const parseSiteAssessmentsRequestResponseToSiteAssessments = (
  siteAssessments: SiteAssessmentsRequestResponse[] | undefined
): SiteAssessment[] | undefined => {
  if (!siteAssessments) {
    return undefined;
  }

  const parsed: SiteAssessment[] = [];

  siteAssessments.forEach((siteAssessment: SiteAssessmentsRequestResponse) => {
    parsed.push({
      clientSiteIdentifier: siteAssessment.ClientSiteIdentifier || undefined,
      cesiumLayerSettingsId: siteAssessment.CesiumLayerSettingsId || undefined,
      date: siteAssessment.Date || undefined,
      powerProviderId: siteAssessment.PowerProviderId || undefined,
      fiberProviderId: siteAssessment.FiberProviderId || undefined,
      fiberHandHoleUri: siteAssessment.FiberHandHoleUri || undefined,
      statusId: siteAssessment.StatusId || undefined,
      storyUrl: siteAssessment.StoryUrl || undefined,
      tilesetUrl: siteAssessment.TilesetUrl || undefined,
      modelGeoReferenced: siteAssessment.ModelGeoReferenced || false,
      modelCenterAtOrigin: siteAssessment.ModelCenterAtOrigin || false,
      id: siteAssessment.Id || undefined,
      siteListMemberId: siteAssessment.SiteListMemberId || undefined,
      dataReceived: siteAssessment.DataReceived || undefined,
      dataType: siteAssessment.DataType || undefined,
      aerotriangulationStart: siteAssessment.AerotriangulationStart || undefined,
      aerotriangulationEnd: siteAssessment.AerotriangulationEnd || undefined,
      reconstructionStart: siteAssessment.ReconstructionStart || undefined,
      reconstructionEnd: siteAssessment.ReconstructionEnd || undefined,
      cadprepFileStart: siteAssessment.CadprepFileStart || undefined,
      cadprepFileEnd: siteAssessment.CadprepFileEnd || undefined,
      uploadedInternally: siteAssessment.UploadedInternally || undefined,
      uploadedToClientSite: siteAssessment.UploadedToClientSite || undefined,
      lastUserIdUpdated: siteAssessment.LastUserIdUpdated || undefined,
      cadprepFileUrl: siteAssessment.CadprepFileUrl || undefined,
      threeMXLink: siteAssessment.ThreeMXLink || undefined,
      clientId: siteAssessment.ClientId || undefined,
      uavoperatorId: siteAssessment.UavoperatorId || undefined,
      cadprepUploadedInternally: siteAssessment.CadprepUploadedInternally || undefined,
      processingStatus: siteAssessment.ProcessingStatus || undefined,
      storage: siteAssessment.Storage || undefined,
      dataTypeId: siteAssessment.DataTypeId || undefined,
      processingWorkerId: siteAssessment.ProcessingWorkerId || undefined,
      processingWorkerHostname: siteAssessment.ProcessingWorkerHostname || undefined,
      processingWorkerHostnameIp: siteAssessment.ProcessingWorkerHostnameIp || undefined,
      processingWorkerHostnameExtIp: siteAssessment.ProcessingWorkerHostnameExtIp || undefined,
      images: siteAssessment.Images || undefined,
      thumbnails: siteAssessment.Thumbnails || undefined,
      thumbnailsFailed: siteAssessment.ThumbnailsFailed || undefined,
      processingCodeVersion: siteAssessment.ProcessingCodeVersion || undefined,
      lastLogId: siteAssessment.LastLogId || undefined,
      lastLogTimestamp: siteAssessment.LastLogTimestamp || undefined,
      action: siteAssessment.Action || undefined,
      settings: siteAssessment.Settings || undefined,
      storageFolder: siteAssessment.StorageFolder || undefined,
      storageBucket: siteAssessment.StorageBucket || undefined,
      virtualTourUrl: siteAssessment.VirtualTourUrl || undefined,
      assessmentTag: siteAssessment.AssessmentTag || undefined,
      viewerUrl: siteAssessment.ViewerUrl || undefined,
      lastQcRecordStatus: siteAssessment.LastQcRecordStatus
        ? (siteAssessment.LastQcRecordStatus as any)
        : '',
      droneVendorCompanyId: siteAssessment.DroneVendorCompanyId || undefined,
      createdBy: siteAssessment.CreatedBy || undefined,
      qcForms: siteAssessment.QcForms || undefined,
      dateCreated: siteAssessment.DateCreated || undefined,
      uavOperatorName: siteAssessment.UavOperatorName || undefined,
      flightAnalysisReportUrl: siteAssessment.FlightAnalysisReportUrl || undefined,
      files: siteAssessment.Files?.map((item) => ({
        fileFormat: item.FileFormat || undefined,
        filePurpose: item.FilePurpose || undefined,
        uri: item.Uri || undefined,
      })),
      vendor: parseVendorRequestResponseToVendor(siteAssessment.Vendor),
      setting: siteAssessment.Setting || undefined,
      siteAssessmentImages: siteAssessment.SiteAssessmentImages?.map(
        (image: SiteAssessmentImagesResponse) => parseSiteImageResponseToSite(image)
      ),
      tenantId: siteAssessment.TenantId || undefined,
      exif: siteAssessment.Exif || undefined,
      zipUrl: siteAssessment.ZipUrl || undefined,
      zipJobStatus: siteAssessment.ZipJobStatus || undefined,
      dataTypeNavigation: siteAssessment.DataTypeNavigation || undefined,
      lastUserIdUpdatedNavigation: siteAssessment.LastUserIdUpdatedNavigation || undefined,
      siteListMember: siteAssessment.SiteListMember || undefined,
      tenant: siteAssessment.Tenant || undefined,
      uavoperator: siteAssessment.Uavoperator || undefined,
      tilesetSetting: siteAssessment.TilesetSetting || undefined,
      cadPrepJobQueues: siteAssessment.CadPrepJobQueues || undefined,
      coldStorageQueues: siteAssessment.ColdStorageQueues || undefined,
      fileDownloadPermissions: siteAssessment.FileDownloadPermissions || undefined,
      fileDownloadQueues: siteAssessment.FileDownloadQueues || undefined,
      fileDownloadRequests: siteAssessment.FileDownloadRequests || undefined,
      fileTransferRequests: siteAssessment.FileTransferRequests || undefined,
      fileUploadLogs: siteAssessment.FileUploadLogs || undefined,
      fileUploadRequests: siteAssessment.FileUploadLogs || undefined,
      otiqrevisions: siteAssessment.Otiqrevisions || undefined,
      processSettings: siteAssessment.ProcessSettings || undefined,
      scaleCoefficients: siteAssessment.ScaleCoefficients || undefined,
      droneVendorCompany: siteAssessment.DroneVendorCompany || undefined,
      title: siteAssessment.Title || undefined,
    });
  });

  return parsed;
};

const parseSiteImageResponseToSite = (
  image: SiteAssessmentImagesResponse
): SiteAssessmentImages => ({
  id: image.Id,
  siteAssessmentId: image.SiteAssessmentId,
  folder: image.Folder,
  file: image.File,
  thumbnail: image.Thumbnail,
  failed: image.Thumbnail,
  index: image.Index,
  points: image.Points ? parseTiePoints(image.Points) : undefined,
  latitude: image.Latitude,
  longitude: image.Longitude,
  absoluteAltitude: image.AbsoluteAltitude,
  relativeAltitude: image.RelativeAltitude,
  gimbalYawDegree: image.GimbalYawDegree,
  exif: image.Exif,
  exifJson: image.ExifJson,
  createdDate: image.CreatedDate,
  exifDateCreated: image.ExifDateCreated,
  imageMetadataRequests: image.ImageMetadataRequests,
});

const parseTiePoints = (points: string): TiePoints => {
  const parsedPoints: any = JSON.parse(points);
  return {
    Ax: parsedPoints.ax,
    Ay: parsedPoints.ay,
    Bx: parsedPoints.bx,
    By: parsedPoints.by,
  };
};


const parseVendorRequestResponseToVendor = (
  vendor: VendorRequestResponse | undefined
): Vendor | undefined => {
  if (vendor) {
    return {
      id: vendor.Id,
      companyId: vendor.CompanyId,
      name: vendor.Name,
      userUpdateId: vendor.UserUpdateId,
    };
  }

  return undefined;
};

const parseSitePeopleRequestResponseToSitePeople = (
  sitePeople: SitePeopleRequestResponse[] | undefined
): any[] | undefined => {
  if (!sitePeople) {
    return undefined;
  }

  const parsed: any[] = [];

  sitePeople.forEach((sitePerson) => {
    parsed.push({
      id: sitePerson.Id,
      siteListMemberId: sitePerson.SiteListMemberId,
      siteListMemberRoleId: sitePerson.SiteListMemberRoleId,
      userId: sitePerson.UserId,
      userUpdateId: sitePerson.UserUpdateId,
      siteListMemberRole: parseSiteListMemberRoleRequestResponseToSiteListMemberRole(
        sitePerson.SiteListMemberRole
      ),
      userInfo: parseUserInfoRequestResponseToUserInfo(sitePerson.UserInfo),
    });
  });

  return parsed;
};

const parseSiteListMemberRoleRequestResponseToSiteListMemberRole = (
  siteListMemberRole: SiteListMemberRoleRequestResponse | undefined
): SiteListMemberRole | undefined => {
  if (siteListMemberRole) {
    return {
      id: siteListMemberRole.Id,
      companyId: siteListMemberRole.CompanyId,
      name: siteListMemberRole.Name,
      userUpdateId: siteListMemberRole.UserUpdateId,
    };
  }
  return undefined;
};

const parseUserInfoRequestResponseToUserInfo = (
  userInfo: UserInfoRequestResponse | undefined
): any | undefined => {
  if (userInfo) {
    return {
      id: userInfo.Id,
      companyId: userInfo.CompanyId,
      firstName: userInfo.FirstName,
      lastName: userInfo.LastName,
      phoneNumber: userInfo.PhoneNumber,
      email: userInfo.Email,
      role: userInfo.Role,
      userId: userInfo.UserId,
      phoneVerified: userInfo.PhoneVerified,
      loginEnabled: userInfo.LoginEnabled,
      invited: userInfo.Invited,
      currentCompanyId: userInfo.CurrentCompanyId,
      fullName: userInfo.FullName,
      companyName: userInfo.CompanyName,
      company: userInfo.Company,
      dateCreated: userInfo.DateCreated,
    };
  }
  return undefined;
};

const parseSiteRequestResponseToSiteRoot = (siteRoot: SiteRootRequestResponse): SiteRoot => ({
  id: siteRoot.Id,
  siteListId: siteRoot.SiteListId,
  companySiteId: siteRoot.CompanySiteId,
  companyMarket: siteRoot.CompanyMarket,
  siteId: siteRoot.SiteId,
  lastUpdateUserId: siteRoot.LastUpdateUserId,
  region: siteRoot.Region,
  tenantId: siteRoot.TenantId,
  MKT_ID: siteRoot.MKT_ID,
  AOI_Code: siteRoot.AOI_Code,
  site: parseSiteRequestResponseToSite(siteRoot.Site),
  siteAssessments: parseSiteAssessmentsRequestResponseToSiteAssessments(siteRoot.SiteAssessments),
  sitePeople: parseSitePeopleRequestResponseToSitePeople(siteRoot.SitePeople),
});

const parseOwnerRequestResponseToOwner = (
  owner: OwnerRequestResponse | undefined
): Owner | undefined => {
  if (owner) {
    return {
      id: owner.Id,
      name: owner.Name,
      shortName: owner.ShortName,
      abbreviation: owner.Abbreviation,
      website: owner.Website,
      user: owner.User,
      accountsPayableEmail: owner.AccountsPayableEmail,
      billToAddress: owner.BillToAddress,
      accountsReceivableEmail: owner.AccountsReceivableEmail,
      billFromAddress: owner.BillFromAddress,
      usersCount: owner.UsersCount,
      isVendor: owner.IsVendor,
      isTowerOwner: owner.IsTowerOwner,
      tenantName: owner.TenantName,
      tenantId: owner.TenantId,
    };
  }
  return undefined;
};

const uncapitalizeObjectKey = (string: string) => {
  return string.includes("I_") ? string.replace("I_", "") : string.charAt(0).toLowerCase() + string.slice(1);
}

const uncapitalizeObjectKeys = (obj: any, assessmentId: string): any => {
  if (Array.isArray(obj)) {
    return obj.map((newObj) => uncapitalizeObjectKeys(newObj, assessmentId)).map(i => {
      if (typeof i === 'object') i.assessmentId = assessmentId;
      return i;
    });
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => ({
      ...acc,
      [uncapitalizeObjectKey(key)]: uncapitalizeObjectKeys(value, assessmentId)
    }), {});
  }

  return obj;
}

const capitalizeObjectKey = (string: any) => {
  return !isNaN(string) ? "I_"+string : string;
}

const capitalizeObjectKeys = (obj: any): any => { // despite the name, this function does not actually capitalize the keys - that happens on the backend. What this function actually does is it converts numerical object keys to "I_XX" strings
  if (Array.isArray(obj)) {
    return obj.map(capitalizeObjectKeys);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => ({
      ...acc,
      [capitalizeObjectKey(key)]: capitalizeObjectKeys(value)
    }), {});
  }

  return obj;
}

const parseCesiumLayersResponse = (cesiumLayers: any) => {
  return uncapitalizeObjectKeys(cesiumLayers, cesiumLayers.SiteAssessmentId);
};

export const fetchSite = async (assessmentId: string): Promise<SiteRoot> => {
  try {
    const response = await api.fetchSite(assessmentId);
    return parseSiteRequestResponseToSiteRoot(response);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const parseCesiumLayersRequest = (payload: any) => {
  return capitalizeObjectKeys(payload);
}

export const fetchCesiumLayers = async (cesiumLayersId: string): Promise<any> => {
  try {
    const response = await api.fetchCesiumLayers(cesiumLayersId);
    return parseCesiumLayersResponse(response);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const saveCesiumLayers = async (assessmentId: string, layerSettingsId: string|undefined, payload: any): Promise<any> => {
  try {
    layerSettingsId ?
      await api.updateCesiumLayers(layerSettingsId, parseCesiumLayersRequest(payload))
      : await api.saveCesiumLayers(assessmentId, parseCesiumLayersRequest(payload));
  } catch (e) {
    console.error(e);
    throw e;
  }
};