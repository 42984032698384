import request from "../request";
import endpoints from "./endpoints";
import { TilesetDataRequestResponse } from "./types";

const fetchTileset = async (assessmentId: string) =>
  request(endpoints.getTileset(assessmentId), {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

const saveTilesetEditorParams = async (
  assessmentId: string,
  tilesetData: Partial<TilesetDataRequestResponse>
) =>
  request(endpoints.updateTileset(assessmentId), {
    method: "PUT",
    body: JSON.stringify(tilesetData),
    headers: { "Content-Type": "application/json" },
  });
const updateGroundPointHeightPosition = async (
  assessmentId: string,
  groundPointHeight: number
) =>
  // axios({
  //   method: "post",
  //   url: `/app/tileset-settings/save/${SASId}`,
  //   data: {
  //     GroundPointHeight: height,
  //   },
  // }).then(function (response) {
  //   console.log(response.data);
  // });
  request(endpoints.getTileset(assessmentId), {
    method: "POST",
    body: JSON.stringify({
      GroundPointHeight: groundPointHeight,
    }),
    headers: { "Content-Type": "application/json" },
  });

export { fetchTileset, saveTilesetEditorParams };
