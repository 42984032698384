import {useState, useCallback, useEffect, useRef} from "react";
import { useParams } from "react-router-dom";
import { TilesetViewer as Viewer } from "../../components/TilesetViewer";
import LoadSpinner from "../../components/LoadSpinner";
import { useTileset } from "../../hooks/useTileset";
import {Cesium3DTileset, Viewer as CesiumViewer} from "cesium";
import {AddModel} from "../../section/@tileset/AddModel";
import {Container, PlannerModelContainer} from "../TilesetBuilder/styles";
import {ModelCard} from "../../section/@tileset/ModelCard";
import {usePlannerTransformations, useSitePlanner} from "../../hooks";
import {PlannerActions} from "../../@types";

export const TilesetViewer = () => {
  const [sitePlannerEnabled, setSitePlannerEnabled] = useState(localStorage.getItem("sitePlannerEnabled") === "true");
  const {
    isLoading,
    error,
    tilesetData,
    initialTilesetSettings,
    fetchTilesetData,
  } = useTileset();
  const { assessmentId } = useParams();
  const cesiumViewerRef = useRef<CesiumViewer>();
  const cesiumTilesetRef = useRef<Cesium3DTileset>();

  const {
    plannerModels,
    selectedPlannerModel,
    currentAction,
    addedPlannerModel,
    clearSelectedPlannerModel,
    addPlannerModel,
    zoomToPlannerModel,
    editPlannerModel,
    removePlannerModel,
    removedPlannerModel
  } = useSitePlanner();
  const {
    newPlannerModel,
    newPlannerPrimitive,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
    removePlannerModel: removePlannerModelTransformations,
  } = usePlannerTransformations(cesiumViewerRef.current);

  const updateCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
  };

  const updateCesiumTilesetRef = (tilesetRef: Cesium3DTileset) => {
    cesiumTilesetRef.current = tilesetRef;
    return tilesetRef;
  };

  useEffect(() => {
    if (addedPlannerModel && addedPlannerModel.ionId) {
      newPlannerModel(addedPlannerModel);
    } else if (addedPlannerModel && addedPlannerModel.shape) {
      newPlannerPrimitive(addedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedPlannerModel]);

  useEffect(() => {
    if (removedPlannerModel) {
      removePlannerModelTransformations(removedPlannerModel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removedPlannerModel]);

  useEffect(() => {
    disableCurrentEditor();
    if (currentAction) {
      if (currentAction === PlannerActions.ZOOM) {
        zoomToSelectedPlannerModel();
      }
      if (currentAction === PlannerActions.EDIT) {
        editSelectedPlannerModel();
      }
    }
  }, [
    currentAction,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
  ]);

  const getTileset = useCallback(() => {
    if (!assessmentId) return;
    fetchTilesetData(assessmentId);
  }, [assessmentId, fetchTilesetData]);

  useEffect(() => {
    getTileset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.addEventListener("toggleSitePlanner", () => {
      setSitePlannerEnabled(localStorage.getItem("sitePlannerEnabled") === "true")
    })
  }, []);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  if (isLoading) {
    return <LoadSpinner />;
  }

  const isEditingPlannerModel = (plannerModelId: string) => {
    if (!selectedPlannerModel) return;
    return (
        plannerModelId === selectedPlannerModel.id &&
        currentAction === PlannerActions.EDIT
    );
  };

  if (tilesetData) {
    return <>
      <Viewer
        getCesiumViewerRef={updateCesiumViewerRef}
        getCesiumTilesetRef={updateCesiumTilesetRef}
        tilesetSettings={initialTilesetSettings}
      />
      {sitePlannerEnabled && <Container>
        <AddModel
            disablePlaceModelButton={!!addedPlannerModel}
            onAddModel={addPlannerModel}
        />
        <PlannerModelContainer>
          {plannerModels.map((plannerModel) => (
              <ModelCard
                  model={plannerModel}
                  key={plannerModel.id}
                  onZoomClick={zoomToPlannerModel}
                  onEditClick={editPlannerModel}
                  onFinishEditClick={clearSelectedPlannerModel}
                  onDeleteClick={removePlannerModel}
                  editing={isEditingPlannerModel(plannerModel.id)}
              />
          ))}
        </PlannerModelContainer>
      </Container>}
    </>;
  }
  return <></>;
};
