export type TilesetData = {
  id: string;
  siteAssessmentId: string;
  storageFolder: string;
  s3Link: string;
  latitude: number;
  longitude: number;
  siteLatitude: number;
  siteLongitude: number;
  tilesetHeight: number;
  tilesetHeading: number;
  tilesetPitch: number;
  tilesetRoll: number;
  tilesetX: number;
  tilesetY: number;
  tilesetZ: number;
  tilesetScale: number;
  tilesetGroundPointHeight: number;
  storage: string;
  appendix: string;
  edit: boolean;
  max: number;
  min: number;
  siteId: string;
  storyUrl: string;
  tilesetUrl: string;
  modelGeoReferenced: boolean;
  modelCenterAtOrigin: boolean;
};

export type TilesetSettings = {
  height: number;
  pitch: number;
  heading: number;
  roll: number;
  scale: number;
  latitude: number;
  longitude: number;
};

export type TilesetEditorParams = TilesetSettings & {
  offsetX: number;
  offsetY: number;
  offsetZ: number;
};

export type ModelOption = {
  ionId?: number;
  shape?: string;
  label: string;
};

export type ModelOptionGroup = {
  label: string;
  models: ModelOption[];
};

export type PlannerModel = {
  ionId?: number;
  shape?: string;
  id: string;
  label: string;
};

export enum PlannerActions {
  ZOOM = "ZOOM",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export type SiteRoot = {
  id: string | undefined;
  siteListId: string | undefined;
  companySiteId: string | undefined;
  companyMarket: string | undefined;
  siteId: string | undefined;
  lastUpdateUserId: string | undefined;
  region: string | undefined;
  tenantId: string | undefined;
  MKT_ID: string | undefined;
  AOI_Code: string | undefined;
  site: Site | undefined;
  siteAssessments: SiteAssessment[] | undefined;
  sitePeople: any[] | undefined;

};


export type Site = {
  id: string | undefined;
  ownerId: string | undefined;
  ownerSiteId: string | undefined;
  structureTypeId: string | undefined;
  assetClassId: string | undefined;
  ownershipTypeId: string | undefined;
  statusId: string | undefined;
  shortName: string | undefined;
  addressLine1: string | undefined;
  addressCity: string | undefined;
  addressState: string | undefined;
  postalCode: string | undefined;
  countyName: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  accessCode: string | undefined;
  accessInstructions: string | undefined;
  user: string | undefined;
  structureHeightAgl: number | undefined;
  assetClassName: string | undefined;
  structureTypeName: string | undefined;
  floodZoneDesignation: string | undefined;
  elevation: number | undefined;
  elevationResolution: number | undefined;
  owner: Owner | undefined;
};

export type SiteAssessmentFile = {
  fileFormat: string | undefined;
  filePurpose: string | undefined;
  uri: string | undefined;
};

export type SiteAssessment = {
  clientSiteIdentifier?: string;
  cesiumLayerSettingsId?: string;
  date?: Date;
  powerProviderId?: string;
  fiberProviderId?: string;
  fiberHandHoleUri?: string;
  statusId?: string;
  storyUrl?: string;
  tilesetUrl?: string;
  modelGeoReferenced?: boolean;
  modelCenterAtOrigin?: boolean;
  id?: string;
  siteListMemberId?: string;
  dataReceived?: string;
  dataType?: string;
  aerotriangulationStart?: string;
  aerotriangulationEnd?: string;
  reconstructionStart?: string;
  reconstructionEnd?: string;
  cadprepFileStart?: string;
  cadprepFileEnd?: string;
  uploadedInternally?: string;
  uploadedToClientSite?: string;
  lastUserIdUpdated?: string;
  cadprepFileUrl?: string;
  threeMXLink?: string;
  clientId?: string;
  uavoperatorId?: string;
  cadprepUploadedInternally?: string;
  processingStatus?: string;
  storage?: string;
  dataTypeId?: string;
  processingWorkerId?: string;
  processingWorkerHostname?: string;
  processingWorkerHostnameIp?: string;
  processingWorkerHostnameExtIp?: string;
  images?: number;
  thumbnails?: number;
  thumbnailsFailed?: number;
  processingCodeVersion?: string;
  lastLogId?: string;
  lastLogTimestamp?: string;
  action?: string;
  settings?: any;
  storageFolder?: string;
  storageBucket?: string;
  virtualTourUrl?: string;
  assessmentTag?: string;
  viewerUrl?: string;
  lastQcRecordStatus?: string;
  droneVendorCompanyId?: string;
  createdBy?: string;
  qcForms?: string;
  dateCreated?: Date;
  flightAnalysisReportUrl?: string;
  uavOperatorName?: string;
  files?: SiteAssessmentFile[];
  vendor?: Vendor;
  setting?: any;
  title?: string;
  description?: string;
  siteAssessmentImages?: SiteAssessmentImages[];
  tenantId?: string;
  exif?: any | string | null;
  zipUrl?: string;
  zipJobStatus?: string;
  dataTypeNavigation?: string;
  lastUserIdUpdatedNavigation?: string;
  siteListMember?: string;
  tenant?: string;
  uavoperator?: string;
  tilesetSetting?: string;
  cadPrepJobQueues?: any[];
  coldStorageQueues?: any[];
  fileDownloadPermissions?: any[];
  fileDownloadQueues?: any[];
  fileDownloadRequests?: any[];
  fileTransferRequests?: any[];
  fileUploadLogs?: any[];
  fileUploadRequests?: any[];
  otiqrevisions?: any[];
  processSettings?: any[];
  scaleCoefficients?: any[];
  droneVendorCompany?: string;
};


export type SiteAssessmentImages = {
  id: string;
  siteAssessmentId: string;
  folder: string;
  file: string;
  thumbnail: boolean;
  failed: boolean;
  index: string | undefined;
  points: TiePoints | undefined;
  latitude: number;
  longitude: number;
  absoluteAltitude: number;
  relativeAltitude: number;
  gimbalYawDegree: number;
  exif: Object | string | null;
  exifJson: string;
  createdDate: string | undefined;
  exifDateCreated: string | undefined;
  imageMetadataRequests: [];
};
export type TiePoints = {
  Ax: string;
  Ay: string;
  Bx: string;
  By: string;
};

export type Vendor = {
  id: string | undefined;
  companyId: string | undefined;
  name: string | undefined;
  userUpdateId: string | undefined;
};

export type Owner = {
  id: string | undefined;
  name: string | undefined;
  shortName: string | undefined;
  abbreviation: string | undefined;
  website: string | undefined;
  user: string | undefined;
  accountsPayableEmail: string | undefined;
  billToAddress: string | undefined;
  accountsReceivableEmail: string | undefined;
  billFromAddress: string | undefined;
  usersCount: string | undefined;
  isVendor?: boolean;
  isTowerOwner?: boolean;
  tenantName: string,
  tenantId: string
};

export type SiteListMemberRole = {
  id: string | undefined;
  companyId: string | undefined;
  name: string | undefined;
  userUpdateId: string | undefined;
};





export enum SiteImagesFolders {
  Accessroad = 'accessroad',
  Compound = 'compound',
  Drone = 'drone',
  Facade = 'facade',
  Ground = 'ground',
  Hotspot = 'hotspot',
  Panorama = 'panorama',
  Rooftop = 'rooftop',
  Tower = 'tower',
}

export type SiteImage = {
  absoluteAltitude: number;
  exif: Object | string | null;
  folder: SiteImagesFolders;
  originalFolderName: string;
  gymbal: number;
  id: string;
  pictureFile: string;
  pictureLink: string;
  points: TiePoints | null;
  relativeAltitude: number;
  thumbnail: string;
};
