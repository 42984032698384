import { takeLatest } from 'redux-saga/effects';

// types
import { SiteActionType } from '../types';


import { fetchSite, fetchCesiumLayers, saveCesiumLayers} from './sites';


const sagas = [
  takeLatest(SiteActionType.FETCH_SITE, fetchSite),
  takeLatest(SiteActionType.FETCH_CESIUM_LAYERS, fetchCesiumLayers),
  takeLatest(SiteActionType.SAVE_CESIUM_LAYERS, saveCesiumLayers),
];

export default sagas;
