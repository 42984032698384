const DOTNET_API_ENDPOINT = process.env.REACT_APP_DOTNET_API_ENDPOINT;

const endpoints = {
  getTileset: (assessmentId: string) =>
    `${DOTNET_API_ENDPOINT}/api/CesiumTileset/${assessmentId}`,
  updateTileset: (assessmentId: string) =>
    `${DOTNET_API_ENDPOINT}/api/CesiumTileset/${assessmentId}`,
  updateTilesetGroundPointHeight: (assessmentId: string) =>
    `${DOTNET_API_ENDPOINT}/api/${assessmentId}`, //NOT SET YET
};
export default endpoints;
