import { useCallback, useEffect, useRef } from "react";

import { CesiumMovementEvent } from "resium";

import { PlannerModelTileset } from "../section/@tileset/Builder/types";
import { PlannerModel } from "../@types";

import {
  Color,
  defined,
  Entity,
  PositionProperty,
  Property,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  TransformEditor,
  Viewer as CesiumViewer,
  Math as CesiumMath,
  Ellipsoid,
  Cartesian3,
  Cartographic,
  Cesium3DTileset,
  ShadowMode,
  IonResource,
  HeadingPitchRange,
} from "cesium";
import { useSitePlanner } from "./useSitePlanner";

export const usePlannerTransformations = (cesiumViewer?: CesiumViewer) => {
  const {
    selectedPlannerModel,
    clearSelectedPlannerModel,
    clearAddedPlannerModel,
  } = useSitePlanner();
  const screenSpaceEventHandlerRef = useRef<ScreenSpaceEventHandler>();
  const plannerModelTilesetsetsRef = useRef<PlannerModelTileset[]>([]);
  const mouseHandlerPointEntityRef = useRef<Entity>();
  const currentTransformEditor = useRef<TransformEditor>();

  const buildNewModel = useCallback(async (plannerModel: PlannerModel) => {
    if (!plannerModel || !plannerModel.ionId) {
      alert("Select model");
      return;
    }

    const model = await new Promise<Cesium3DTileset>((resolve) => {
      new Cesium3DTileset({
        url: IonResource.fromAssetId(plannerModel.ionId!),
        shadows: ShadowMode.DISABLED,
        luminanceAtZenith: 3,
      }).readyPromise.then((tileset) => resolve(tileset));
    });

    if (!model) return;
    return model;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createTransformEditor = (
    tileset: Cesium3DTileset,
    cesiumViewer: CesiumViewer
  ) => {
    const editor = new TransformEditor({
      container: cesiumViewer.container,
      scene: cesiumViewer.scene,
      tileset: tileset,
      setModelCenterAsTransformationOrigin: false,
      initialPosition: tileset.boundingSphere.center.clone(),
      pixelSize: 100,
      maximumSizeInMeters: Infinity,
    });
    return editor;
  };

  const destroyTransformEditor = (editor: TransformEditor) => {
    editor.destroy();
  };

  const createMouseHandlerPointEntity = (cesiumViewer: CesiumViewer) => {
    const entity = cesiumViewer.entities.add({
      point: {
        show: false,
        pixelSize: 10,
        color: Color.RED,
      },
    });
    mouseHandlerPointEntityRef.current = entity;
  };

  const mouseMoveEventHandler = useCallback(
    (movement: CesiumMovementEvent) => {
      if (!selectedPlannerModel) return;
      if (!cesiumViewer) return;
      if (!mouseHandlerPointEntityRef.current) return;
      if (!movement.endPosition) return;

      const position = cesiumViewer.scene.pickPosition(movement.endPosition);

      if (position && defined(position)) {
        mouseHandlerPointEntityRef.current.position =
          position as unknown as PositionProperty;
        mouseHandlerPointEntityRef.current.point!.show =
          true as unknown as Property;
      } else {
        mouseHandlerPointEntityRef.current.point!.show =
          false as unknown as Property;
      }
    },
    [cesiumViewer, selectedPlannerModel]
  );

  const leftClickEventHandler = useCallback(
    (movement: CesiumMovementEvent) => {
      if (!selectedPlannerModel) return;
      if (!cesiumViewer) return;
      if (!mouseHandlerPointEntityRef.current) return;
      if (!movement.position) return;
      if (!screenSpaceEventHandlerRef.current) return;
      const plannerModelTileset = plannerModelTilesetsetsRef.current.find(
        ({ id }) => id === selectedPlannerModel.id
      );
      if (!plannerModelTileset) return;

      const editor = createTransformEditor(
        plannerModelTileset.tileset,
        cesiumViewer
      );

      const position = cesiumViewer.scene.pickPosition(movement.position);

      if (cesiumViewer.scene.sampleHeightSupported) {
        const cartographic = Cartographic.fromCartesian(
          position,
          Ellipsoid.WGS84
        );
        const height = cesiumViewer.scene.sampleHeight(cartographic);
        const newPosition = Cartesian3.fromDegrees(
          CesiumMath.toDegrees(cartographic.longitude),
          CesiumMath.toDegrees(cartographic.latitude),
          height
        );
        if (!editor) return;
        editor.viewModel.position = newPosition;
        destroyTransformEditor(editor);
      }
      mouseHandlerPointEntityRef.current.point!.show =
        false as unknown as Property;

      clearSelectedPlannerModel();
      clearAddedPlannerModel();

      screenSpaceEventHandlerRef.current.removeInputAction(
        ScreenSpaceEventType.LEFT_CLICK
      );
      screenSpaceEventHandlerRef.current.removeInputAction(
        ScreenSpaceEventType.MOUSE_MOVE
      );
      document.body.style.cursor = "auto";
    },
    [
      cesiumViewer,
      clearAddedPlannerModel,
      clearSelectedPlannerModel,
      selectedPlannerModel,
    ]
  );

  const addMouseEventHandlers = useCallback(
    (cesiumViewer: CesiumViewer) => {
      document.body.style.cursor = "crosshair";
      screenSpaceEventHandlerRef.current = new ScreenSpaceEventHandler(
        cesiumViewer.scene.canvas
      );
      if (
        cesiumViewer.scene.pickPositionSupported &&
        screenSpaceEventHandlerRef.current
      ) {
        screenSpaceEventHandlerRef.current.removeInputAction(
          ScreenSpaceEventType.LEFT_CLICK
        );
        screenSpaceEventHandlerRef.current.setInputAction(
          leftClickEventHandler,
          ScreenSpaceEventType.LEFT_CLICK
        );
        screenSpaceEventHandlerRef.current.removeInputAction(
          ScreenSpaceEventType.MOUSE_MOVE
        );
        screenSpaceEventHandlerRef.current.setInputAction(
          mouseMoveEventHandler,
          ScreenSpaceEventType.MOUSE_MOVE
        );
      }
    },
    [leftClickEventHandler, mouseMoveEventHandler]
  );

  const newPlannerModel = useCallback(
    async (plannerModel: PlannerModel) => {
      if (!cesiumViewer) return;
      const tileset = await buildNewModel(plannerModel);
      if (tileset) {
        cesiumViewer.scene.primitives.add(tileset);
        const plannerModelTileset: PlannerModelTileset = {
          tileset,
          id: plannerModel.id,
        };
        plannerModelTilesetsetsRef.current = [
          ...plannerModelTilesetsetsRef.current,
          plannerModelTileset,
        ];
      }

      addMouseEventHandlers(cesiumViewer);
    },

    [addMouseEventHandlers, buildNewModel, cesiumViewer]
  );

  const newPlannerPrimitive = useCallback((plannerModel: PlannerModel) => {
      if (!cesiumViewer) return;
      cesiumViewer.entities.add({
          name: "Primitive Box",
          position: Cartesian3.fromDegrees(-100.0, 40.0, 300000.0),
          box: {
              dimensions: new Cartesian3(100000.0, 200000.0, 300000.0),
              fill: false,
              outline: true,
              outlineColor: Color.GREEN,
          }
      });
      console.log(cesiumViewer);
  }, [cesiumViewer]);

  useEffect(() => {
    if (cesiumViewer) {
      createMouseHandlerPointEntity(cesiumViewer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cesiumViewer]);

  const zoomToSelectedPlannerModel = useCallback(() => {
    if (!cesiumViewer) return;
    const plannerModelTileset = plannerModelTilesetsetsRef.current.find(
      ({ id }) => id === selectedPlannerModel?.id
    );
    if (plannerModelTileset) {
      cesiumViewer.flyTo(plannerModelTileset.tileset, {
        duration: 2,
        offset: new HeadingPitchRange(
          0.0,
          -0.5,
          plannerModelTileset.tileset.boundingSphere.radius * 4.0
        ),
      });
      clearSelectedPlannerModel();
    }
  }, [cesiumViewer, clearSelectedPlannerModel, selectedPlannerModel]);

  const editSelectedPlannerModel = useCallback(() => {
    if (!cesiumViewer) return;
    if (!selectedPlannerModel) return;
    if (!currentTransformEditor.current) {
      const plannerModelTileset = plannerModelTilesetsetsRef.current.find(
        ({ id }) => id === selectedPlannerModel.id
      );
      if (!plannerModelTileset) return;
      currentTransformEditor.current = createTransformEditor(
        plannerModelTileset.tileset,
        cesiumViewer
      );
    }

    currentTransformEditor.current.viewModel.activate();
    //   clearSelectedPlannerModel();
  }, [cesiumViewer, selectedPlannerModel]);

  const disableCurrentEditor = useCallback(() => {
    if (currentTransformEditor.current) {
      destroyTransformEditor(currentTransformEditor.current);
      currentTransformEditor.current = undefined;
    }
  }, []);

  const removePlannerModel = useCallback(
    (plannerModel: PlannerModel) => {
      if (!cesiumViewer) return;
      const plannerModelTileset = plannerModelTilesetsetsRef.current.find(
        ({ id }) => id === plannerModel.id
      );
      if (!plannerModelTileset) return;
      cesiumViewer.scene.primitives.remove(plannerModelTileset.tileset);
      plannerModelTilesetsetsRef.current =
        plannerModelTilesetsetsRef.current.filter(
          ({ id }) => id !== plannerModel.id
        );
    },
    [cesiumViewer]
  );

  return {
    newPlannerModel,
    zoomToSelectedPlannerModel,
    editSelectedPlannerModel,
    disableCurrentEditor,
    removePlannerModel,
    newPlannerPrimitive
  };
};
