import { SiteAction, SiteActionType, SiteReducer, SitePayload, SiteState } from './types';

export const initialState: SiteState = {
  site: undefined,
  cesium: undefined,
  loading: false,
  error: undefined,
  currentSiteAssessmentId: undefined,

};

const setLoading: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  loading: !!payload?.loading,
});

const setSite: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  site: (payload && payload.site) ?? state.site,
});

const setCesiumLayers: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  cesium: (payload && payload.cesium) ?? state.cesium,
});

const setCurrentSiteAssessmentId: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  currentSiteAssessmentId:
    (payload && payload.currentSiteAssessmentId) ?? state.currentSiteAssessmentId,
});


const setError: SiteReducer = (state: SiteState, payload?: SitePayload) => ({
  ...state,
  error: payload ? payload.error : undefined,
});

const clearState: SiteReducer = () => ({
  ...initialState,
});

const reducerMap = new Map<SiteActionType, SiteReducer>([
  [SiteActionType.SET_SITE, setSite],
  [SiteActionType.SET_CESIUM_LAYERS, setCesiumLayers],
  [SiteActionType.SET_CURRENT_ASSESSMENT_ID, setCurrentSiteAssessmentId],
  [SiteActionType.SET_LOADING, setLoading],
  [SiteActionType.SET_ERROR, setError],
  [SiteActionType.CLEAR, clearState],
]);

const reducer = (state: SiteState = initialState, action: SiteAction): SiteState => {
  const reducer = reducerMap.get(action.type);
  if (reducer) return reducer(state, action.payload);
  return state;
};

export default reducer;