import { SiteRoot } from '../../@types';
import { SiteAction, SiteActionType } from './types';

const actions = {
  setSite: (site: SiteRoot): SiteAction => ({
    payload: { site },
    type: SiteActionType.SET_SITE,
  }),
  fetchSite: (assessmentId?: string): SiteAction => ({
    payload: { assessmentId },
    type: SiteActionType.FETCH_SITE,
  }),

  setCesiumLayers: (cesium: any): SiteAction => ({
    payload: { cesium },
    type: SiteActionType.SET_CESIUM_LAYERS,
  }),
  fetchCesiumLayers: (cesiumLayersId?: string): SiteAction => ({
    payload: { cesiumLayersId },
    type: SiteActionType.FETCH_CESIUM_LAYERS,
  }),

  saveCesiumLayers: (assessmentId: string, layerSettingsId: string | undefined, cesium: any): SiteAction => ({
    payload: { assessmentId, layerSettingsId, cesium },
    type: SiteActionType.SAVE_CESIUM_LAYERS,
  }),

  setLoading: (loading: boolean): SiteAction => ({
    payload: { loading },
    type: SiteActionType.SET_LOADING,
  }),
  setCurrentSiteAssessmentId: (currentSiteAssessmentId: string): SiteAction => ({
    payload: { currentSiteAssessmentId },
    type: SiteActionType.SET_CURRENT_ASSESSMENT_ID,
  }),
  
  setError: (error: Error): SiteAction => ({
    payload: { error },
    type: SiteActionType.SET_ERROR,
  }),
  clearState: (): SiteAction => ({
    type: SiteActionType.CLEAR,
  }),
  

};

export default actions;
