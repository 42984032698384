import {
  TransformEditorViewModel,
  Viewer as CesiumViewer,
  Cesium3DTileset,
} from "cesium";

import { useCallback, useEffect, useRef, useState } from "react";

import Cesium from "../Cesium";
import { useTileset } from "../../hooks/useTileset";
import { useTilesetTransformations } from "../../hooks/useTilesetTransformations";
import { TilesetViewerProps } from "./types";
import { useSearchParams } from "react-router-dom";
import './TilesetViewer.css';

export const TilesetViewer = ({
  getCesiumViewerRef,
  getCesiumTilesetRef,
  transformEditorActive,
  tilesetSettings,
}: TilesetViewerProps) => {
  const { error, allTilesLoaded, loadTileset } = useTileset();
  const {
    modelCenterAtOrigin,
    transformPosition,
    addOnHomeEventListener,
    zoomToTileset,
    initialTransformation,
  } = useTilesetTransformations();

  let [searchParams] = useSearchParams();
  let hideExportButton = searchParams.get("hideExportButton") === "true";

  const transformEditorViewModelRef = useRef<TransformEditorViewModel>();
  const cesiumTilesetRef = useRef<Cesium3DTileset>();
  const cesiumViewerRef = useRef<CesiumViewer>();

  const [render, rerender] = useState(false);

  const _getCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
  };

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  const fetchCesium3DTileset = useCallback(async () => {
    if (!cesiumViewerRef.current) return;
    
    try {
      cesiumTilesetRef.current = await loadTileset();
      if (getCesiumTilesetRef && cesiumTilesetRef.current) {
        getCesiumTilesetRef(cesiumTilesetRef.current);
      }
      
      if (!cesiumTilesetRef.current) {
        throw new Error("Something wrong happened on loading tileset");
      }
      
      cesiumViewerRef.current.scene.primitives.add(cesiumTilesetRef.current);
      zoomToTileset(cesiumViewerRef.current, cesiumTilesetRef.current);
      addOnHomeEventListener(cesiumViewerRef.current, cesiumTilesetRef.current);

      rerender(!render);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOnHomeEventListener, loadTileset, zoomToTileset, cesiumViewerRef]);

  const addTransformEditorViewModel = useCallback(() => {
    if (!cesiumViewerRef.current || !cesiumTilesetRef.current) {
      return;
    }
    
    transformEditorViewModelRef.current = new TransformEditorViewModel({
      tileset: cesiumTilesetRef.current,
      scene: cesiumViewerRef.current.scene,
      setModelCenterAsTransformationOrigin: !modelCenterAtOrigin,
      initialPosition: cesiumTilesetRef.current.boundingSphere.center.clone(),
      pixelSize: 100,
      maximumSizeInMeters: Infinity,
    });
  }, [modelCenterAtOrigin]);

  const loadCesium3DTileset = useCallback(async () => {
    await fetchCesium3DTileset();
    
    if (!cesiumTilesetRef.current) {
      return;
    }
    
    const _tilesetSettings = initialTransformation(
      tilesetSettings,
        cesiumTilesetRef.current
    );
    
    addTransformEditorViewModel();
    
    if (!modelCenterAtOrigin && transformEditorViewModelRef.current) {
      transformPosition(
        transformEditorViewModelRef.current,
          cesiumTilesetRef.current,
        _tilesetSettings
      );
    }
      
    if (transformEditorActive) {
      transformEditorViewModelRef.current!.activate();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transformEditorViewModelRef.current && cesiumTilesetRef.current) {
      transformPosition(
        transformEditorViewModelRef.current,
          cesiumTilesetRef.current,
        tilesetSettings
      );
    }
  }, [tilesetSettings, transformPosition]);

  useEffect(() => {
    loadCesium3DTileset();
  }, [loadCesium3DTileset]);

  useEffect(() => {
    if (!cesiumViewerRef.current) return;
    getCesiumViewerRef && getCesiumViewerRef(cesiumViewerRef.current);
  }, [getCesiumViewerRef]);

  useEffect(() => {
    if (!cesiumTilesetRef.current) return;
    getCesiumTilesetRef && getCesiumTilesetRef(cesiumTilesetRef.current);
  }, [getCesiumTilesetRef]);

  return (
    <Cesium
      getCesiumViewerRef={_getCesiumViewerRef}
      cesiumTileset={cesiumTilesetRef.current}
      isLoading={!allTilesLoaded}
      hideExportMeasurementButton={hideExportButton}
    />
  );
};
