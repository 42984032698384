import { put, call } from 'redux-saga/effects';
import actions from '../actions';
import * as siteServices from '../../../services/site';
import { SiteAction } from '../types';
import { SiteRoot } from '../../../@types';

export function* fetchSite({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId } = payload;

  yield put(actions.setLoading(true));

  try {
    const response: SiteRoot = yield call(siteServices.fetchSite, assessmentId);
  
      yield put(actions.setSite(response));
  
      const currentSiteAssessmentId =
        assessmentId ||
        (response && response.siteAssessments && response.siteAssessments[0].id) ||
        undefined;
  
      if (currentSiteAssessmentId) {
        yield put(actions.setCurrentSiteAssessmentId(currentSiteAssessmentId));
        
      }
    
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.cesiumLayersId) {
    return;
  }

  const { cesiumLayersId } = payload;

  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    const response = yield call(siteServices.fetchCesiumLayers, cesiumLayersId);

    yield put(actions.setCesiumLayers(response));
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* saveCesiumLayers({ payload }: SiteAction) {
  if (!payload || !payload.assessmentId) {
    return;
  }

  const { assessmentId, layerSettingsId, cesium } = payload;
  yield put(actions.setLoading(true));

  try {
    // @ts-ignore
    yield call(siteServices.saveCesiumLayers, assessmentId, layerSettingsId, cesium);
  } catch (e : any) {
    yield put(actions.setError(e?.message as Error));
  } finally {
    yield put(actions.setLoading(false));
  }
}