import { useEffect } from 'react';
import { Provider } from "react-redux";
import { Router } from "./router";
import Store from "./store";
import { ThemeProvider } from "ca-react-component-lib";
import ErrorLocalStorage from "./components/ErrorLocalStorage";
import "cesium/Build/Cesium/Widgets/widgets.css";
import "cesium/Build/Cesium/Widgets/reactStyle.css";

function App() {
  let localStorageSupported = false; // Temporary workaround - show an error page if localStorage is not available
  try { if (localStorage) localStorageSupported = true; } catch (e) { } // @TODO - rework so the app works without localStorage
  return (!localStorageSupported) ? <ErrorLocalStorage /> : (
    <Provider store={Store}>
      <ThemeProvider>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
