import { TilesetData } from "../../@types";
import { mountTilesetURL } from "../../utils";
import * as api from "./api";
import { TilesetDataRequestResponse } from "./types";

const parseTilesetRequestResponseToTileset = (
  data: TilesetDataRequestResponse
): TilesetData => ({
  id: data.Id,
  siteAssessmentId: data.SiteAssessmentId,
  storageFolder: data.StorageFolder,
  s3Link: data.S3Link,
  latitude: data.Lat,
  siteLatitude: data.SiteLatitude,
  siteLongitude: data.SiteLongitude,
  longitude: data.Longitude,
  tilesetHeight: data.TilesetHeight,
  tilesetHeading: data.TilesetHeading,
  tilesetPitch: data.TilesetPitch,
  tilesetRoll: data.TilesetRoll,
  tilesetX: data.TilesetX,
  tilesetY: data.TilesetY,
  tilesetZ: data.TilesetZ,
  tilesetScale: data.TilesetScale,
  tilesetGroundPointHeight: data.TilesetGroundPointHeight,
  storage: data.Storage,
  appendix: data.Appendix,
  edit: data.Edit,
  max: data.Max,
  min: data.Min,
  siteId: data.SiteId,
  storyUrl: data.StoryUrl,
  tilesetUrl:
    data.TilesetUrl ?? mountTilesetURL(data.Storage, data.StorageFolder),
  modelCenterAtOrigin: !!data.ModelCenterAtOrigin,
  modelGeoReferenced: !!data.ModelGeoReferenced,
});
const parseTilesetToTilesetRequestResponse = (
  data: Partial<TilesetData>
): Partial<TilesetDataRequestResponse> => ({
  Id: data.id,
  SiteAssessmentId: data.siteAssessmentId,
  StorageFolder: data.storageFolder,
  S3Link: data.s3Link,
  Lat: data.latitude,
  Longitude: data.longitude,
  TilesetHeight: data.tilesetHeight,
  TilesetHeading: data.tilesetHeading,
  TilesetPitch: data.tilesetPitch,
  TilesetRoll: data.tilesetRoll,
  TilesetX: data.tilesetX,
  TilesetY: data.tilesetY,
  TilesetZ: data.tilesetZ,
  TilesetScale: data.tilesetScale,
  TilesetGroundPointHeight: data.tilesetGroundPointHeight,
  Storage: data.storage,
  Appendix: data.appendix,
  Edit: data.edit,
  Max: data.max,
  Min: data.min,
  SiteId: data.siteId,
  StoryUrl: data.storyUrl,
  TilesetUrl: data.tilesetUrl,
  ModelCenterAtOrigin: !!data.modelCenterAtOrigin,
  ModelGeoReferenced: !!data.modelGeoReferenced,
});

export const fetchTilesetData = async (
  assessmentId: string
): Promise<TilesetData> => {
  try {
    const result: TilesetDataRequestResponse = await api.fetchTileset(
      assessmentId
    );
    return parseTilesetRequestResponseToTileset(result);
  } catch (e: any) {
    throw e;
  }
};

export const saveTilesetEditorParams = async (
  tileset: TilesetData
): Promise<TilesetData> => {
  try {
    const tilesetData = parseTilesetToTilesetRequestResponse(tileset);
    const result: TilesetDataRequestResponse =
      await api.saveTilesetEditorParams(tileset.siteAssessmentId, tilesetData);

    return parseTilesetRequestResponseToTileset(result);
  } catch (e: any) {
    throw e;
  }
};
