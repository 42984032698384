import { Cartesian3 } from "cesium";
import { TilesetData, TilesetEditorParams, TilesetSettings } from "./@types";

export const mountTilesetURL = (storage: string, storageFolder: string) => {
  if (storage === "s3" || storage === "azure") {
    return `https://cci-upload.s3.amazonaws.com/b%2Bt_upload/${storageFolder}/tiles_output/tileset.json`;
  }
  return `https://sitewalkdata.s3.amazonaws.com/${storageFolder}/tiles_output/tileset.json`;
};

export const getNewCartesian = (
  latitude: number,
  longitude: number,
  height: number
) => {
  var offset = height;
  return Cartesian3.fromDegrees(longitude, latitude, offset);
};

export const definedNumber = (value: any, defaultValue: number) => {
  if (value === undefined) return defaultValue;
  if (value === null) return defaultValue;
  return value as number;
};

export const compareTilesetSettings = (
  left: TilesetSettings,
  right: TilesetSettings
) => {
  if (left.heading !== right.heading) return true;
  if (left.pitch !== right.pitch) return true;
  if (left.roll !== right.roll) return true;
  if (left.latitude !== right.latitude) return true;
  if (left.longitude !== right.longitude) return true;
  if (left.height !== right.height) return true;
  if (left.scale !== right.scale) return true;
  return false;
};

export const convertTilesetEditorParamsToTilesetData = (
  tilesetEditorParams: TilesetEditorParams
): Partial<TilesetData> => ({
  tilesetX: tilesetEditorParams.offsetX,
  tilesetY: tilesetEditorParams.offsetY,
  tilesetZ: tilesetEditorParams.offsetZ,
  tilesetHeading: tilesetEditorParams.heading,
  tilesetPitch: tilesetEditorParams.pitch,
  tilesetRoll: tilesetEditorParams.roll,
  tilesetHeight: tilesetEditorParams.height,
  tilesetScale: tilesetEditorParams.scale,
  latitude: tilesetEditorParams.latitude,
  longitude: tilesetEditorParams.longitude,
});
