import { ApplicationState } from '../types';
import { SiteAssessment } from '../../@types';


const _getData = (state: ApplicationState) => state.Site;

const getError = (state: ApplicationState) => _getData(state).error;

const isLoading = (state: ApplicationState) => _getData(state).loading;

const getSite = (state: ApplicationState) => _getData(state).site;

const getCesiumLayers = (state: ApplicationState) => _getData(state).cesium;

const getCurrentSiteAssessmentId = (state: ApplicationState) =>
  _getData(state).currentSiteAssessmentId;


const getCurrentSiteAssessment = (state: ApplicationState) => {
  const siteRoot = _getData(state).site;
  const currentSiteAssessmentId = getCurrentSiteAssessmentId(state);

  if (!siteRoot || !siteRoot.siteAssessments || !currentSiteAssessmentId) {
    return;
  }

  return siteRoot.siteAssessments.find(
    (siteAssessment: SiteAssessment) => siteAssessment.id === currentSiteAssessmentId
  );
};


const selectors = {
  getError,
  isLoading,
  getSite,
  getCesiumLayers,
  getCurrentSiteAssessment,
  getCurrentSiteAssessmentId,
};

export default selectors;
