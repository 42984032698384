import {Viewer as CesiumViewer, Cartesian3, Cesium3DTileset} from "cesium";

import { useCallback, useEffect, useRef, useState } from "react";
import { TilesetSettings } from "../../../@types";

import { TilesetViewer } from "../../../components/TilesetViewer";
import { useTilesetTransformations } from "../../../hooks/useTilesetTransformations";
import { compareTilesetSettings } from "../../../utils";
import { TilesetEditorProps } from "./types";

export const TilesetEditor = ({
  tilsetsetEditorParams,
  onTilesetEditorParamsChanged,
  updateCesiumViewerRef,
  updateCesiumTilesetRef,
}: TilesetEditorProps) => {
  const { getNewTilesetEditorParams } = useTilesetTransformations();
  const cesiumViewerRef = useRef<CesiumViewer>();
  const cesiumTilesetRef = useRef<Cesium3DTileset>();
  const [innerTilesetSettings, setInnerTilesetSettings] =
    useState<TilesetSettings>(tilsetsetEditorParams);
  const newTransformPositionRef = useRef<Cartesian3>();

  const innerTilsetsetEditorParamsRef = useRef(tilsetsetEditorParams);
  const debounceTimerRef = useRef<any>();
  const throttlingTimerRef = useRef<any>();
  const debounceTimeout = 250;
  const throttlingTimeout = 250;
  const fromEditorViewModel = useRef(false);

  const getCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
    updateCesiumViewerRef(viewerRef);
  };

  const getCesiumTilesetRef = (tilesetRef: Cesium3DTileset) => {
    cesiumTilesetRef.current = tilesetRef;
    updateCesiumTilesetRef(tilesetRef);
  };

  const throttlingUpdateInnerTilsetsetEditorParams = useCallback(() => {
    if (throttlingTimerRef.current) {
      clearTimeout(throttlingTimerRef.current);
    }
    throttlingTimerRef.current = setTimeout(() => {
      setInnerTilesetSettings(innerTilsetsetEditorParamsRef.current);
      throttlingTimerRef.current = null;
    }, throttlingTimeout);
  }, []);

  const debounceNewPosition = useCallback(() => {
    if (debounceTimerRef.current) return;
    debounceTimerRef.current = setTimeout(() => {
      fromEditorViewModel.current = true;

      onTilesetEditorParamsChanged(innerTilsetsetEditorParamsRef.current);

      debounceTimerRef.current = null;
    }, debounceTimeout);
  }, [onTilesetEditorParamsChanged]);

  const handleTransformPositionChanged = useCallback(
    (event: any) => {
      if (event.detail) {
        const newTransformPosition: Cartesian3 = event.detail
          .value as Cartesian3;
        newTransformPositionRef.current = newTransformPosition;
        innerTilsetsetEditorParamsRef.current = getNewTilesetEditorParams(
          tilsetsetEditorParams,
          newTransformPosition
        );
        debounceNewPosition();
        throttlingUpdateInnerTilsetsetEditorParams();
      }
    },
    [
      debounceNewPosition,
      getNewTilesetEditorParams,
      throttlingUpdateInnerTilsetsetEditorParams,
      tilsetsetEditorParams,
    ]
  );

  useEffect(() => {
    document.addEventListener(
      "onTransformPositionChanged",
      handleTransformPositionChanged
    );
    return () => {
      document.removeEventListener(
        "onTransformPositionChanged",
        handleTransformPositionChanged
      );
    };
  }, [handleTransformPositionChanged]);

  useEffect(() => {
    if (
      compareTilesetSettings(tilsetsetEditorParams, innerTilesetSettings) &&
      !fromEditorViewModel.current
    ) {
      setInnerTilesetSettings(tilsetsetEditorParams);
    }
    fromEditorViewModel.current = false;
  }, [innerTilesetSettings, tilsetsetEditorParams]);

  return (
    <TilesetViewer
      getCesiumViewerRef={getCesiumViewerRef}
      getCesiumTilesetRef={getCesiumTilesetRef}
      transformEditorActive
      tilesetSettings={innerTilesetSettings}
    />
  );
};
