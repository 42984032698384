import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// components
import { TilesetEditor as Editor } from "../../section/@tileset/Editor";
import LoadSpinner from "../../components/LoadSpinner";
import TilesetInputs from "./TilesetInputs";
import TilesetSliders from "./TilesetSliders";

// hooks
import { useTileset } from "../../hooks/useTileset";
import { useTilesetTransformations } from "../../hooks/useTilesetTransformations";

// constants
import { SIDEBAR_HEIGHT,TEXTFIELD_WIDTH } from "./TilesetInputs/constants";
import { TilesetData, TilesetEditorParams } from "../../@types";

import {Cesium3DTileset, Math as CesiumMath, Viewer as CesiumViewer } from "cesium";
import {definedNumber} from "../../utils";

// ca
import {
  Dialog,
  Typography,
  Stack,
  Paper,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  TextField,
  Divider,
  FontAwesome,
  useResizeDetector
} from "ca-react-component-lib";


export const TilesetEditor = () => {
  const { height: windowHeight } = useResizeDetector();

  const {
    isLoading,
    isSavingNewTilesetEditorParams,
    error,
    tilesetData,
    fetchTilesetData,
    saveNewTilesetEditorParams,
    loadTileset
  } = useTileset();

  const { initialTilesetEditorParams, zoomToTileset, getOriginCoordinates } = useTilesetTransformations();
  const prevIsSavingNewTilesetEditorParamsRef = useRef(
    isSavingNewTilesetEditorParams
  );

  const [tilesetEditorParams, setTilesetEditorParams] = useState(
    initialTilesetEditorParams
  );

  const { assessmentId } = useParams();
  const [showSuccessSaveDialog, setShowSuccessSaveDialog] = useState(false);

  const [showResetValuesDialog, setShowResetValuesDialog] = useState(false);
  const [scaleInput, setScaleInput] = useState<string>();

  const [inputHeight, setInputHeight] = useState<string>();
  const [inputHeading, setInputHeading] = useState<string>();
  const [inputPitch, setInputPitch] = useState<string>();
  const [inputRoll, setInputRoll] = useState<string>();

  const debounceTimerRef = useRef<any>();
  const debounceTimeout = 20;

  const throttlingTimerRef = useRef<any>();
  const throttlingTimeout = 1000;

  const cesiumViewerRef = useRef<CesiumViewer>();
  const cesiumTilesetRef = useRef<Cesium3DTileset>();

  const getTileset = useCallback(() => {
    if (!assessmentId) return;
    fetchTilesetData(assessmentId);
  }, [assessmentId, fetchTilesetData]);

  const formatEditorValue = (tilesetEditorParams: TilesetEditorParams) => {
    const latitude = tilesetEditorParams.latitude.toFixed(6);
    const longitude = tilesetEditorParams.longitude.toFixed(6);
    const heading = CesiumMath.toDegrees(tilesetEditorParams.heading);
    const pitch = CesiumMath.toDegrees(tilesetEditorParams.pitch);
    const roll = CesiumMath.toDegrees(tilesetEditorParams.roll);
    const scale = tilesetEditorParams.scale.toString();
    const height = tilesetEditorParams.height;

    return {
      latitude,
      longitude,
      height,
      heading,
      pitch,
      roll,
      scale,
    };
  };

  const { latitude, longitude, height, heading, pitch, roll } =
      formatEditorValue(tilesetEditorParams);

  useEffect(() => {
    if (!isSavingNewTilesetEditorParams) {
      setTilesetEditorParams(initialTilesetEditorParams);
      const { scale } = formatEditorValue(initialTilesetEditorParams);
      setScaleInput(scale);
    }
  }, [initialTilesetEditorParams, isSavingNewTilesetEditorParams]);

  useEffect(() => {
    getTileset();
  }, [getTileset]);

  useEffect(() => {
    if (error) {
      alert(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (
      !isSavingNewTilesetEditorParams &&
      prevIsSavingNewTilesetEditorParamsRef.current &&
      !error
    ) {
      setShowSuccessSaveDialog(true);
    }
    prevIsSavingNewTilesetEditorParamsRef.current =
      isSavingNewTilesetEditorParams;
  }, [isSavingNewTilesetEditorParams, error]);

  useEffect(() => {
    (typeof tilesetData?.tilesetHeading !== "undefined") && setInputHeading(CesiumMath.toDegrees(tilesetData.tilesetHeading).toString());
    (typeof tilesetData?.tilesetPitch !== "undefined") && setInputPitch(CesiumMath.toDegrees(tilesetData.tilesetPitch).toString());
    (typeof tilesetData?.tilesetRoll !== "undefined") && setInputRoll(CesiumMath.toDegrees(tilesetData.tilesetRoll).toString());
  }, [tilesetData]);

  useEffect(() => {
    if (!inputHeight || height !== +inputHeight) setInputHeight(height.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const onTilesetEditorParamsChanged = useCallback(
    (newTilesetEditorParams: TilesetEditorParams) => {
      setTilesetEditorParams(newTilesetEditorParams);
    },
    []
  );

  const trimStringNumber = (stringNumber?: string) => {
    if (!stringNumber || isNaN(+stringNumber) || /[.!?]$/.test(stringNumber)) return stringNumber || "0";
    if (stringNumber === "-0") { // Need to check for a negative zero here because ("-0").toString() returns 0
      return "-0";
    } else {
      return parseFloat(stringNumber).toLocaleString('fullwide', {maximumFractionDigits:3}).toString();
    }
  }

  const updateCesiumViewerRef = (viewerRef: CesiumViewer) => {
    cesiumViewerRef.current = viewerRef;
  }
  const updateCesiumTilesetRef = (tilesetRef: Cesium3DTileset) => {
    cesiumTilesetRef.current = tilesetRef;
  }

  const formatTilesetDataToTilesetEditorParams = (tilesetData: TilesetData) => {
    return {
      height: definedNumber(tilesetData.tilesetHeight, 90),
      heading: definedNumber(tilesetData.tilesetHeading, 0),
      pitch: definedNumber(tilesetData.tilesetPitch, 0),
      roll: definedNumber(tilesetData.tilesetRoll, -1.5707963267949001),
      scale: definedNumber(tilesetData.tilesetScale, 1),
      latitude: definedNumber(tilesetData.latitude, 0),
      longitude: definedNumber(tilesetData.longitude, 0),
      offsetX: definedNumber(tilesetData.tilesetX, 0),
      offsetY: definedNumber(tilesetData.tilesetY, 0),
      offsetZ: definedNumber(tilesetData.tilesetZ, 0),
      originLatitude: definedNumber(tilesetData.siteLatitude, 0),
      originLongitude: definedNumber(tilesetData.siteLongitude, 0),
    };
  };

  const throttlingUpdateInnerTilsetsetEditorParams = (scaleInput: string) => {
    if (throttlingTimerRef.current) {
      clearTimeout(throttlingTimerRef.current);
    }
    let scale = Number(scaleInput);
    if (!scale) {
      scale = tilesetEditorParams.scale;
    }

    throttlingTimerRef.current = setTimeout(() => {
      const _tilesetEditorParams = {
        ...tilesetEditorParams,
        scale,
      };
      const { scale: inputScale } = formatEditorValue(_tilesetEditorParams);
      setScaleInput(inputScale);
      setTilesetEditorParams(_tilesetEditorParams);
      throttlingTimerRef.current = null;
    }, throttlingTimeout);
  };

  const debounceNewTilesetEditorParams = (
    _tilesetEditorParams: TilesetEditorParams
  ) => {
    if (debounceTimerRef.current) return;
    debounceTimerRef.current = setTimeout(() => {
      setTilesetEditorParams(_tilesetEditorParams);
      debounceTimerRef.current = null;
    }, debounceTimeout);
  };

  const resetTilesetEditorParams = () => {
    setShowResetValuesDialog(false);
    resetToOrigin(true);
    const { scale } = formatEditorValue(initialTilesetEditorParams);
    setScaleInput(scale);
  };

  const resetToOrigin = async (fullReset: boolean = false) => {
    const savedTileset = tilesetData ? formatTilesetDataToTilesetEditorParams(tilesetData) : false;
    if (savedTileset) {
      setTilesetEditorParams({
        ...savedTileset,
        latitude: (fullReset && savedTileset.latitude) ? savedTileset.latitude : savedTileset.originLatitude,
        longitude: (fullReset && savedTileset.longitude) ? savedTileset.longitude : savedTileset.originLongitude,
        heading: (!fullReset && inputHeading) ? +CesiumMath.toRadians(+inputHeading) : savedTileset.heading,
        pitch: (!fullReset && inputPitch) ? +CesiumMath.toRadians(+inputPitch) : savedTileset.pitch,
        roll: (!fullReset && inputRoll) ? +CesiumMath.toRadians(+inputRoll) : savedTileset.roll,
        height: (!fullReset && inputHeight) ? +inputHeight : savedTileset.height,
      });
      if (fullReset) {
        setInputHeading(trimStringNumber(CesiumMath.toDegrees(savedTileset.heading).toString()));
        setInputPitch(trimStringNumber(CesiumMath.toDegrees(savedTileset.pitch).toString()));
        setInputRoll(trimStringNumber(CesiumMath.toDegrees(savedTileset.roll).toString()));
      }
    } else {
      const tileset = await loadTileset();
      if (!tileset) return;
      const origin = getOriginCoordinates(tileset);
      setTilesetEditorParams({...initialTilesetEditorParams, ...origin});
    }

    setTimeout(() => { // setTimeout 0 used here to make sure that we zoom to the updated position
      if (cesiumTilesetRef?.current && cesiumViewerRef?.current) {
        zoomToTileset(cesiumViewerRef.current, cesiumTilesetRef?.current);
      }
    }, 0);
  };

  if (isLoading) {
    return <LoadSpinner />;
  }

  return (
    <div style={{ height: '100%' }} >
      {tilesetData && (
        <Editor
          tilsetsetEditorParams={tilesetEditorParams}
          onTilesetEditorParamsChanged={onTilesetEditorParamsChanged}
          updateCesiumViewerRef={updateCesiumViewerRef}
          updateCesiumTilesetRef={updateCesiumTilesetRef}
        />
      )}
      <Paper sx={{
        p: 3,
        position: 'absolute',
        top: '20px',
        left: '20px',
        height: '100%',
        maxHeight: `${SIDEBAR_HEIGHT}px`,
        overflow: 'auto',
        }} >
        <Stack direction="column">
          <ListItem sx={{ p: 0, mb: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="overline">
              Rotation
            </Typography>
            <Button onClick={() => resetToOrigin(false)} sx={{ fontSize: '0.75rem' }}>
              <FontAwesome icon="fa-location-crosshairs" sx={{ mr: 0.5 }}/> Set to Origin
            </Button>
          </ListItem>
          <ListItem divider sx={{ p: 0, pb: 4 }}>
            <Stack  sx={{ height: 48, }}>
              <Stack sx={{ width: 65 }}>
                <Typography gutterBottom variant="caption">Heading:</Typography>
              </Stack>
              <Stack sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TilesetInputs
                  value={inputHeading || "0"}
                  onValueChange={(value: number, originValue) => {
                    setInputHeading(trimStringNumber(originValue));
                    if (isNaN(value)) return;
                    debounceNewTilesetEditorParams({
                      ...tilesetEditorParams,
                      heading: value,
                    });
                  }}
                  isValid={(value) => isNaN(value)}
                  />
              </Stack>
            </Stack>
          </ListItem>
                 
          <ListItem divider sx={{ p: 0, mt: 1, pb: 2 }}>
            <Stack>
              <Stack>
                <Typography gutterBottom  variant="caption">Pitch:</Typography>
              </Stack>
             <Stack  sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
              <TilesetInputs
                  value={trimStringNumber(inputPitch)}
                  isValid={(value) => isNaN(value)}
                  onValueChange={(value: number, originValue) => {
                    setInputPitch(originValue);
                    if (isNaN(value)) return;
                    debounceNewTilesetEditorParams({
                      ...tilesetEditorParams,
                      pitch: +value,
                    })
                  }}
              />
             </Stack>
            </Stack>
          </ListItem>
        </Stack>
                
            <Stack direction="column" sx={{  mt: 1 }}>
              <Stack direction="column" sx={{ width: 65 }}>
                <Typography gutterBottom  variant="caption">Roll:</Typography>
              </Stack>
              <Stack  sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <TilesetInputs 
                  value={trimStringNumber(inputRoll)}
                  onValueChange={(value: number, originValue) => {
                    setInputRoll(originValue);
                    if (isNaN(value)) return;
                    debounceNewTilesetEditorParams({
                      ...tilesetEditorParams,
                      roll: +value,
                    })
                  }}
                  isValid={(value) => isNaN(value)}
                />
              </Stack>
            </Stack>
            <Stack direction='row' sx={{ py: 1, mt: 2, justifyContent: "space-around" }}>
              <Stack>
                <Typography gutterBottom variant='overline'>Scale:</Typography>
                 <TextField
                   sx={{ width: `${TEXTFIELD_WIDTH}px`}}
                   value={scaleInput}
                   type='number'
                   onChange={(e: any) => {
                    setScaleInput(e.target.value);
                    throttlingUpdateInnerTilsetsetEditorParams(e.target.value);
                  }}
                />
              </Stack>
             <Divider orientation="vertical" flexItem={true} />
             <Stack>
               <Typography gutterBottom variant="overline">Height:</Typography>
                <TextField
                  sx={{ width: `${TEXTFIELD_WIDTH}px`}}
                  focused={false}
                  type={'text'}
                  value={trimStringNumber(inputHeight)}
                  onChange={(e: any) => {
                    if (isNaN(e.currentTarget.value)) return;
                    setInputHeight(e.currentTarget.value);
                    debounceNewTilesetEditorParams({
                      ...tilesetEditorParams,
                      height: Number(e.currentTarget.value),
                    })
                  }}
                  error={typeof inputHeight !== "undefined" && isNaN(+inputHeight)}
                  onKeyDown={(e:any) => {
                    let newVal;
                    if (e.key === "ArrowUp") {
                      newVal = Number(e.target.value) + 0.1;
                    } else if (e.key === "ArrowDown") {
                      newVal = Number(e.target.value) - 0.1;
                    }
                    if (newVal === undefined) return;
                    setInputHeight(newVal.toString());
                    debounceNewTilesetEditorParams({
                      ...tilesetEditorParams,
                      height: Number(newVal),
                    })
                  }}
                />
              </Stack>
             </Stack>
       
          <Stack  sx={{ mt: 2,  }}>
            <Typography variant="overline" sx={{ mb: 1 }}>
              Cartographic Position
            </Typography>
            <Stack direction='row' sx={{justifyContent: "space-around"}}>
              <Stack>
                <Typography sx={{width: `${TEXTFIELD_WIDTH}px`}}  variant="caption">Lat:</Typography>
                <Typography fontWeight="bold">{latitude}</Typography>
              </Stack>
                <Divider orientation="vertical" flexItem={true} />
                <Stack sx={{pl: 2}} >
                <Typography sx={{width: '110px'}}  variant="caption">Long:</Typography>
                <Typography fontWeight="bold">{longitude}</Typography>
              </Stack>
            </Stack>
          </Stack>
           
          <Stack sx={{ mt: 3 }}>
              <Button
                variant={isSavingNewTilesetEditorParams ? "text" : "contained"}
                fullWidth
                isLoading={isSavingNewTilesetEditorParams}
                sx={{ mr: 1 }}
                onClick={() => saveNewTilesetEditorParams(tilesetEditorParams)}
                >
                Save
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{ mt: 1 }}
                onClick={() => setShowResetValuesDialog(true)}
                >
                Reset
              </Button>
          </Stack>
        </Paper>
        <>
       
        <Dialog open={showResetValuesDialog}>
          <DialogTitle>Reset editor values?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to reset editor values to the stored values?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetTilesetEditorParams}>Yes</Button>
            <Button
              onClick={() => {
                setShowResetValuesDialog(false);
              }}
              >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <>
        <Dialog open={showSuccessSaveDialog}>
          <DialogTitle>Saved successfully.</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The new settings has been saved successfully.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowSuccessSaveDialog(false);
              }}
              >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <TilesetSliders
          windowHeight={windowHeight}
          roll={roll}
          heading={heading}
          pitch={pitch}
          debounceNewTilesetEditorParams={debounceNewTilesetEditorParams}
          tilesetEditorParams={tilesetEditorParams}
      />
    </div>
    );
};

